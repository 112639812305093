import SimpleBar from "simplebar";

jQuery(function ($) {
	/* -------------------------------------------------------------------------- */
	/*                                BLOCK: Content Slider                       */
	/* -------------------------------------------------------------------------- */
	const $cSlider = $(".content-slider");

	$cSlider.each(function () {
		let $dis = $(this);
		let scrollbar = new SimpleBar($dis[0], {
			forceVisible: true,
			autoHide: 8000,
			scrollbarMaxSize: window.innerWidth / 10,
			scrollbarMinSize: window.innerWidth / 10,
		});
	});
});
