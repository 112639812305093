import Flickity from "flickity-fade";

jQuery(function ($) {
	/* -------------------------------------------------------------------------- */
	/*                     BLOCK: Slider principal - Flickity                     */
	/* -------------------------------------------------------------------------- */
	let $sliderPrincipal = $(".slider-main"),
		totalSlides = $sliderPrincipal.find(".slider-main-item").length,
		$alerts = $sliderPrincipal.find(".slider-main-alerts");

	$sliderPrincipal.each(function () {
		let sliderPrincipalEl = new Flickity(this, {
			cellSelector: ".slider-main-item",
			fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: true,
			wrapAround: true,
			draggable: false,
			//autoPlay: 5000,
			autoPlay: false,
			pauseAutoPlayOnHover: false,
			//accessibility: false,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 65,
				y2: 45,
				x3: 20,
			},
		});

		setTimeout(function () {
			makeOnlyVisibleCellsFocusable();
		}, 100);

		var makeOnlyVisibleCellsFocusable = function () {
			let theSlider = $sliderPrincipal,
				cellSelector = ".slider-main-item";

			var focuseableElemsSelector =
				"a, button, input, textarea, [tabindex=0], select";

			$sliderPrincipal.find(".slider-main-item").each(function (i, elem) {
				$(elem).find(focuseableElemsSelector).attr("tabindex", "-1");
				console.log("modifying", $(elem).find(focuseableElemsSelector));
			});
			//var flickitySelElems = theSlider.data("flickity").selectedElements;
			var flickitySelElems = sliderPrincipalEl.selectedElements;
			flickitySelElems &&
				flickitySelElems.forEach(function (elem) {
					$(elem)
						.find(focuseableElemsSelector)
						.removeAttr("tabindex");
					console.log(
						"removing",
						$(elem).find(focuseableElemsSelector)
					);
				});
		};

		// A11y focus

		// A11y alerts
		sliderPrincipalEl.on("change", function (index) {
			makeOnlyVisibleCellsFocusable();
			if ($alerts.length) {
				$alerts.append(
					`<div role='alert'>Panel ${
						index + 1
					} de ${totalSlides}</div>`
				);
				setTimeout(function () {
					$alerts.empty();
				}, 3000);
			}
		});
	});
});
