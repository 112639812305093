import Flickity from "flickity-fade";

jQuery(function($) {
	/* -------------------------------------------------------------------------- */
	/*                     BLOCK: Slider principal - Flickity                     */
	/* -------------------------------------------------------------------------- */
	let $slider = $(".slider");

	$slider.each(function() {
		let slider = new Flickity(this, {
			fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true
			//adaptiveHeight: true,
			//setGallerySize: false
		});
	});
});
